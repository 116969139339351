.footer {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.footerRoot {
    font-family: 'neuzeits-bookbook';
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 34px 26px 0;
}   

@media screen and (min-width: 1200px)  {
    .footerRoot {
        height: 100px;
    }
}

.footerPageHolder {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.footerItem {
    font-size: 12px;
    font-weight: bold;
    color: white;
    display: inline-block;
    padding: 10px 26px;
}

@media screen and (min-width: 1200px)  {
    .footerItem {
        font-size: 16px;
    }
}

.footerNavLink {
    text-decoration: none;
    color: white;
    display: inline-block;
    border-bottom: 1px solid transparent;
}

.isActive {
    border-bottom: 1px solid black;
}

.homePage .isActive,
.registerPage .isActive {
    border-bottom: 1px solid white;
}

.footerNavLink:hover {
    border-bottom: 1px solid black;
}

.homePage .footerNavLink:hover,
.registerPage .footerNavLink:hover {
    border-bottom: 1px solid white;
}

.footerAuthHolder {
    display: flex;
    flex: 0 0 28%;
    max-width: 28%;
    justify-content: flex-end;
}

.footerAuthHolder footerItem {
    border-bottom: none;
    max-width: none;
}

