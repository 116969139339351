.pageContentTitle {
    font-family: 'neuzeits-bookbook';
    text-transform: uppercase;
    font-size: 35px;
    text-align: center;
    line-height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px)  {
    .pageContentTitle {
        font-size: 45px;
        line-height: 50px;
    }
}

.pageContent {
    display: flex;
    position: relative;
    background: url("./home_bg.svg") 0 0 no-repeat;
    background-position: center center;
    background-size: cover;
    height: calc(100vh - 100px);
    opacity: 0;
    transition: opacity 5s ease-in-out;
}

@media screen and (min-width: 1200px)  {
    .pageContent {
        height: calc(100vh - 100px);
        background-position: -800px -20px;
    }
}

.pageContent.active {
    opacity: 1;
    transition: opacity 5s ease-in-out;
}

@media screen and (min-width: 1200px){
    .pageContent {
        background-position: 0 0;
    }
}
