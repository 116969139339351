.topBarRoot {
    font-family: 'neuzeits-bookbook';
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 34px 26px 0;
    height: 100px;
}

@media screen and (min-width: 1200px)  {
    .topBarRoot {
        height: 100px;
    }
}

.topBarPageHolder {
    display: flex;
    flex: 0 0 70%;
    max-width: 70%;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.topBarItem {
    font-size: 16px;
    margin-bottom: 20px;
    color: white;
    display: inline-block;
    margin-right: 30px;
}

@media screen and (min-width: 1200px)  {
    .topBarItem {
        font-size: 34px;
    }
}

.topBarNavLink {
    text-decoration: none;
    color: white;
    display: inline;
    width: 100%;
    height: 100%;
}

.isActive {
    border-bottom: 1px solid black;
}

.homePage .isActive,
.registerPage .isActive {
    border-bottom: 1px solid white;
}

.topBarNavLink:hover {
    border-bottom: 1px solid black;
}

.homePage .topBarNavLink:hover,
.registerPage .topBarNavLink:hover {
    border-bottom: 1px solid white;
}

.topBarAuthHolder {
    display: flex;
    flex: 0 0 28%;
    max-width: 28%;
    justify-content: flex-end;
}

.topBarAuthHolder .topBarItem {
    border-bottom: none;
    max-width: none;
}

