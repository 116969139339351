.cookiesContent {
    display: flex;
    height: 100%;
    position: relative;
    background: white;
    background-size: cover;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 5s ease-in-out;
}


.cookiesContent.active {
    opacity: 1;
    transition: opacity 5s ease-in-out;
}



@media screen and (min-width: 991px) {
    .cookiesContent {
    }
}

@media screen and (min-width: 1200px) {
    .cookiesContent {
        background-position: 0 0;
    }
}

.privacyTextHolder {
    padding: 40px 70px 80px 26px;
    flex: 0 0 100%;
    max-width: 100%;
}


@media screen and (min-width: 991px) {
    .privacyTextHolder {
        flex: 0 0 80%;
        max-width: 80%;
    }
}

@media screen and (min-width: 1400px){
    .privacyTextHolder {
        /* padding: 80px 70px 80px 26px; */
    }
}

.cookiesContent h2 {
    font-size: 120%;
    line-height: 200%;
}

.privacyText {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
}

.privacyText.table {
    border: 1px solid gray;
    text-transform: none;
    overflow: auto;
}

.privacyText.table table {
    min-width: 650px;
}

.privacyText.table th,
.privacyText.table td {
    border: 1px solid gray;
    padding: 5px;
}

.privacyText.title {
    font-size: 16px;
}

.privacyText ul {
    margin-top: 5px;
    margin-left: 30px;
    list-style: disc;
}
.privacyText ul.abc {
    list-style-type: lower-alpha;
}
.privacyText li {
    margin-bottom: 5px;
}
    
address {
    font-weight: 600;
}