.pageContentTitle {
    font-family: 'neuzeits-bookbook';
    text-transform: uppercase;
    font-size: 45px;
    text-align: center;
    line-height: 50px;
}

.clientsContent {
    background: white;
    height: 100%;
    opacity: 0;
    transition: opacity 5s ease-in-out;
    overflow-x: hidden;
}

.clientsContent.active {
    opacity: 1;
    transition: opacity 5s ease-in-out;
}

.contentHolder {
    display: flex;
    width: 100%;
    padding: 15px 0 60px;
    margin-top: 60px;
    border-top: 1px solid black;
    margin-left: 26px;
    margin-right: 26px;
    flex-wrap: wrap;
}

@media screen and (min-width: 768px){
    .contentHolder {
        flex-wrap: nowrap;
    }
}

.clientsList {
    order: 2;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    min-height: 100vh;
}

@media screen and (min-width: 768px){
    .clientsList {
        order: 1;
        flex: 0 0 60%;
        max-width: 60%;
    }
}


@media screen and (min-width: 1500px){
    .clientsList {
        flex: 0 0 60%;
        max-width: 60%;
    }
}

.clientsItem {
    text-transform: uppercase;
    font-size: 22px;
    cursor: pointer;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
}

.clientsItem.active {
    border-bottom: 1px solid black;
}


@media screen and (min-width: 1200px){
    .clientsItem {
        flex: 0 0 48%;
        max-width: 48%;
    }
}

@media screen and (min-width: 768px){
    .clientsItem {
        font-size: 32px;
    }
}

.clientsDetails {
    order: 1;
    flex: 0 0 100%;
    max-width: 100%;
}

@media screen and (min-width: 768px){
    .clientsDetails {
        order: 2;
        flex: 0 0 40%;
        max-width: 40%;
    }
}

.clientsLogoHolder {
    margin-bottom: 40px;
    min-height: 100px;
    display: flex;
    width: calc(100% - 60px);
    align-items: center;
    justify-content: center;
}

.clientsLogo {
    max-width: 150px;
}

.clientsInfoHolder {
    display: flex;
    padding-top: 20px;
    border-top: 1px solid black;
    margin-right: 60px;
}

.clientsBio {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
    line-height: 23px;
    margin-right: calc(50% - 10px);
}

.clientsUrl {
    display: block;
    text-transform: uppercase;
    word-wrap: break-word;
    width: 10px;
    line-height: 24px;
    flex: 0 0 10px;
    max-width: 10px;
    margin-right: 30px;
    font-size: 18px;
    word-spacing: 12px;
    color: black;
}

body {
    background-color: #fff;
}

.container {
    width: 960px;
    margin: 70px auto 0px auto;
    text-align: center;
}

.spinners {
    min-width: 200%;
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.spinner-block {
    width: 125px;
    text-align: center;
    margin-right: 83px;
}

.spinner-block :nth-child(5n) {
    margin-right: 0px;
}


/* YOU NEED THESE STYLES */
/* spinner style */
.spinner {
    position: relative;
    width: 125px;
    height: 125px;
}
.spinner:before, .spinner:after {
    content: "";
    display: block;
    position: absolute;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
}

/* spinner-1 styles */
@keyframes rotate-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes anti-rotate-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.spinner.spinner-1:before {
    width: 117px;
    height: 117px;
    border-bottom-color: #212121;
    border-right-color: #212121;
    border-top-color: rgba(33, 33, 33, 0);
    border-left-color: rgba(33, 33, 33, 0);
    top: 0px;
    left: 0px;
    animation: rotate-animation 1s linear 0s infinite;
}
.spinner.spinner-1:after {
    width: 81.9px;
    height: 81.9px;
    border-bottom-color: #212121;
    border-right-color: #212121;
    border-top-color: rgba(33, 33, 33, 0);
    border-left-color: rgba(33, 33, 33, 0);
    top: 17.55px;
    left: 17.55px;
    animation: anti-rotate-animation 0.85s linear 0s infinite;
}

/* spinner-2 styles */
@keyframes scale {
    0% {
        transform: scale(1);
        border-style: solid;
    }
    100% {
        transform: scale(0);
        border-style: dashed;
    }
}
.spinner.spinner-2:before {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0px;
    left: 0px;
    animation: scale 1s linear 0s infinite alternate;
}
.spinner.spinner-2:after {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0;
    left: 0;
    animation: scale 1s linear 0s infinite alternate-reverse;
}

/* spinner-3 styles */
@keyframes scale-2 {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(0.7);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
.spinner.spinner-3:before {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0px;
    left: 0px;
    animation: scale-2 1s linear 0s infinite;
}
.spinner.spinner-3:after {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0;
    left: 0;
    opacity: 0;
    animation: scale-2 1s linear 0.5s infinite;
}

/* spinner-4 styles */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.spinner.spinner-4:before {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0px;
    left: 0px;
    overflow: hidden;
}
.spinner.spinner-4:after {
    width: 16.38px;
    height: 16.38px;
    border-width: 3px;
    border-color: #212121;
    top: 50.5px;
    left: 0;
    overflow: hidden;
    transform-origin: 62.5px;
    animation: rotate 1.2s linear 0s infinite;
}

/* spinner-5 styles */
@keyframes border-animate {
    0% {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    25% {
        border-top-color: #212121;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    50% {
        border-top-color: #212121;
        border-right-color: #212121;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    75% {
        border-top-color: #212121;
        border-right-color: #212121;
        border-bottom-color: #212121;
        border-left-color: transparent;
    }
    100% {
        border-top-color: #212121;
        border-right-color: #212121;
        border-bottom-color: #212121;
        border-left-color: #212121;
    }
}
.spinner.spinner-5:before {
    width: 0;
    height: 0;
    border-color: #212121;
    top: 0px;
    left: 0px;
    border-width: 62.5px;
    border-style: solid;
    animation: border-animate 1.6s linear 0s infinite alternate;
}
.spinner.spinner-5:after {
    width: 81.9px;
    height: 81.9px;
    border-color: #212121;
    top: 17.55px;
    left: 17.55px;
    background-color: #fff;
}
