@font-face {
    font-family: 'neuzeits-bookbook';
    src: url('../fonts/neuzeitsbook-webfont.woff2') format('woff2'),
    url('../fonts/neuzeitsbook-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'courier_newregular';
    src: url('../fonts/cour-webfont.woff2') format('woff2'),
    url('../fonts/cour-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

body {
    font-size: 16px;
    font-family: 'courier_newregular';
    background: black;
    /*min-height: 100vh;*/
    /*overflow: hidden;*/
}