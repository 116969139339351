.noMatchContent {
    height: 100vh;
}

.titleHolder {
    height: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.noMatchTitle {
    color: white;
    font-size: 110px;
    font-weight: bold;
    font-family: 'neuzeits-bookbook';
}