/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}


::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

a {
    text-decoration: none;
}

a:focus {
    outline: none;
}
@font-face {
    font-family: 'neuzeits-bookbook';
    src: url(/static/media/neuzeitsbook-webfont.d6943345.woff2) format('woff2'),
    url(/static/media/neuzeitsbook-webfont.a5c6d55c.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'courier_newregular';
    src: url(/static/media/cour-webfont.074e6bc6.woff2) format('woff2'),
    url(/static/media/cour-webfont.9ea49de6.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

body {
    font-size: 16px;
    font-family: 'courier_newregular';
    background: black;
    /*min-height: 100vh;*/
    /*overflow: hidden;*/
}

.bgVideoHolder {
    height: 100vh;
    background: url(/static/media/bg-mobile.f3751aec.jpg) 0 0 repeat-y;
    position: absolute;
    z-index: -2;
    width: 100%;
}

@media screen and (min-width: 768px){
    .bgVideoHolder {
        background: url(/static/media/bg.e4a9be2b.png) 0 0 repeat-y;
    }
}

.bgVideo {
    width: 100vw;
    position: fixed;
    z-index: -1;
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
    .bgVideo {
        height: 100vh;
    }
}

@media screen and (min-width: 1400px)  {
    .bgVideo {
        height: auto;
    }
}

.appContent {
    min-height: 100vh;
}


@media screen and (min-width: 1366px) and (min-height: 1024px) {
    .appContent {
        overflow: hidden;
    }
}

.video {

}

.topBarRoot {
    font-family: 'neuzeits-bookbook';
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 34px 26px 0;
    height: 100px;
}

@media screen and (min-width: 1200px)  {
    .topBarRoot {
        height: 100px;
    }
}

.topBarPageHolder {
    display: flex;
    flex: 0 0 70%;
    max-width: 70%;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.topBarItem {
    font-size: 16px;
    margin-bottom: 20px;
    color: white;
    display: inline-block;
    margin-right: 30px;
}

@media screen and (min-width: 1200px)  {
    .topBarItem {
        font-size: 34px;
    }
}

.topBarNavLink {
    text-decoration: none;
    color: white;
    display: inline;
    width: 100%;
    height: 100%;
}

.isActive {
    border-bottom: 1px solid black;
}

.homePage .isActive,
.registerPage .isActive {
    border-bottom: 1px solid white;
}

.topBarNavLink:hover {
    border-bottom: 1px solid black;
}

.homePage .topBarNavLink:hover,
.registerPage .topBarNavLink:hover {
    border-bottom: 1px solid white;
}

.topBarAuthHolder {
    display: flex;
    flex: 0 0 28%;
    max-width: 28%;
    justify-content: flex-end;
}

.topBarAuthHolder .topBarItem {
    border-bottom: none;
    max-width: none;
}



.icon {
  width: 20px;
  height: 20px;
}


.icon {
  width: 20px;
  height: 20px;
}


.icon {
  width: 20px;
  height: 20px;
}


.icon {
  width: 20px;
  height: 34px;
}

.grecaptcha-badge { 
    visibility: hidden;
}
.footer {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.footerRoot {
    font-family: 'neuzeits-bookbook';
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 34px 26px 0;
}   

@media screen and (min-width: 1200px)  {
    .footerRoot {
        height: 100px;
    }
}

.footerPageHolder {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

.footerItem {
    font-size: 12px;
    font-weight: bold;
    color: white;
    display: inline-block;
    padding: 10px 26px;
}

@media screen and (min-width: 1200px)  {
    .footerItem {
        font-size: 16px;
    }
}

.footerNavLink {
    text-decoration: none;
    color: white;
    display: inline-block;
    border-bottom: 1px solid transparent;
}

.isActive {
    border-bottom: 1px solid black;
}

.homePage .isActive,
.registerPage .isActive {
    border-bottom: 1px solid white;
}

.footerNavLink:hover {
    border-bottom: 1px solid black;
}

.homePage .footerNavLink:hover,
.registerPage .footerNavLink:hover {
    border-bottom: 1px solid white;
}

.footerAuthHolder {
    display: flex;
    flex: 0 0 28%;
    max-width: 28%;
    justify-content: flex-end;
}

.footerAuthHolder footerItem {
    border-bottom: none;
    max-width: none;
}


.aboutContent {
    display: flex;
    height: 100%;
    position: relative;
    background: white;
    background-size: cover;
    flex-wrap: wrap;
    opacity: 0;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}

@media screen and (max-height: 1080px){
    .aboutContent {
        min-height: 100vh;
    }
}

@-moz-document url-prefix() {
    @media screen and (max-height: 1080px){
        .aboutContent {
            min-height: 100vh;
        }
    }
}

.aboutContent.active {
    opacity: 1;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}

@media screen and (min-width: 991px) {
    .aboutContent {
        background-image: url(/static/media/about_bg.359e9e37.svg);
        background-position: -141px 0;
        background-color: transparent;
    }
}

@media screen and (min-width: 1200px) {
    .aboutContent {
        background-position: 0 0;
    }
}

.aboutTextHolder {
    padding: 40px 70px 80px 26px;
    flex: 0 0 100%;
    max-width: 100%;
    height: calc(100vh - 100px);
}

@media screen and (min-width: 991px) {
    .aboutTextHolder {
        height: calc(100vh - 137px);
    }
}

@media screen and (min-width: 991px) {
    .aboutTextHolder {
        flex: 0 0 65%;
        max-width: 65%;
    }
}

@media screen and (min-width: 1400px){
    .aboutTextHolder {
        padding: 80px 70px 80px 26px;
    }
}

.aboutText {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
}

@media screen and (min-width: 500px) and (max-width: 1023px){
    .aboutText {
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px){
    .aboutText {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 1024px){
    .aboutText {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 1281px){
    .aboutText {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 36px;
    }
}

@media screen and (min-height: 768px){
    .aboutText {
        font-size: 28px;
        line-height: 32px;
    }
}

.partnerslist {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 650px;
    margin: 80px auto 0;
}

.partnersItem {
    /* width: 25%; */
    max-width: 200px;
    margin-right: 20px;
    margin-bottom: 30px;

}
.partnersItem img {
    display: block;
    max-width: 100%;
    max-height: 60px;
}

.partnersLogo {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
}
.pageContentTitle {
    font-family: 'neuzeits-bookbook';
    text-transform: uppercase;
    font-size: 45px;
    text-align: center;
    line-height: 50px;
}

.clientsContent {
    background: white;
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
    overflow-x: hidden;
}

.clientsContent.active {
    opacity: 1;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}

.contentHolder {
    display: flex;
    width: 100%;
    padding: 15px 0 60px;
    margin-top: 60px;
    border-top: 1px solid black;
    margin-left: 26px;
    margin-right: 26px;
    flex-wrap: wrap;
}

@media screen and (min-width: 768px){
    .contentHolder {
        flex-wrap: nowrap;
    }
}

.clientsList {
    order: 2;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    min-height: 100vh;
}

@media screen and (min-width: 768px){
    .clientsList {
        order: 1;
        flex: 0 0 60%;
        max-width: 60%;
    }
}


@media screen and (min-width: 1500px){
    .clientsList {
        flex: 0 0 60%;
        max-width: 60%;
    }
}

.clientsItem {
    text-transform: uppercase;
    font-size: 22px;
    cursor: pointer;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
}

.clientsItem.active {
    border-bottom: 1px solid black;
}


@media screen and (min-width: 1200px){
    .clientsItem {
        flex: 0 0 48%;
        max-width: 48%;
    }
}

@media screen and (min-width: 768px){
    .clientsItem {
        font-size: 32px;
    }
}

.clientsDetails {
    order: 1;
    flex: 0 0 100%;
    max-width: 100%;
}

@media screen and (min-width: 768px){
    .clientsDetails {
        order: 2;
        flex: 0 0 40%;
        max-width: 40%;
    }
}

.clientsLogoHolder {
    margin-bottom: 40px;
    min-height: 100px;
    display: flex;
    width: calc(100% - 60px);
    align-items: center;
    justify-content: center;
}

.clientsLogo {
    max-width: 150px;
}

.clientsInfoHolder {
    display: flex;
    padding-top: 20px;
    border-top: 1px solid black;
    margin-right: 60px;
}

.clientsBio {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
    line-height: 23px;
    margin-right: calc(50% - 10px);
}

.clientsUrl {
    display: block;
    text-transform: uppercase;
    word-wrap: break-word;
    width: 10px;
    line-height: 24px;
    flex: 0 0 10px;
    max-width: 10px;
    margin-right: 30px;
    font-size: 18px;
    word-spacing: 12px;
    color: black;
}

body {
    background-color: #fff;
}

.container {
    width: 960px;
    margin: 70px auto 0px auto;
    text-align: center;
}

.spinners {
    min-width: 200%;
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.spinner-block {
    width: 125px;
    text-align: center;
    margin-right: 83px;
}

.spinner-block :nth-child(5n) {
    margin-right: 0px;
}


/* YOU NEED THESE STYLES */
/* spinner style */
.spinner {
    position: relative;
    width: 125px;
    height: 125px;
}
.spinner:before, .spinner:after {
    content: "";
    display: block;
    position: absolute;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
}

/* spinner-1 styles */
@-webkit-keyframes rotate-animation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes rotate-animation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-webkit-keyframes anti-rotate-animation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg);
    }
}
@keyframes anti-rotate-animation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg);
    }
}

.spinner.spinner-1:before {
    width: 117px;
    height: 117px;
    border-bottom-color: #212121;
    border-right-color: #212121;
    border-top-color: rgba(33, 33, 33, 0);
    border-left-color: rgba(33, 33, 33, 0);
    top: 0px;
    left: 0px;
    -webkit-animation: rotate-animation 1s linear 0s infinite;
            animation: rotate-animation 1s linear 0s infinite;
}
.spinner.spinner-1:after {
    width: 81.9px;
    height: 81.9px;
    border-bottom-color: #212121;
    border-right-color: #212121;
    border-top-color: rgba(33, 33, 33, 0);
    border-left-color: rgba(33, 33, 33, 0);
    top: 17.55px;
    left: 17.55px;
    -webkit-animation: anti-rotate-animation 0.85s linear 0s infinite;
            animation: anti-rotate-animation 0.85s linear 0s infinite;
}

/* spinner-2 styles */
@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        border-style: solid;
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
        border-style: dashed;
    }
}
@keyframes scale {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        border-style: solid;
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
        border-style: dashed;
    }
}
.spinner.spinner-2:before {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0px;
    left: 0px;
    -webkit-animation: scale 1s linear 0s infinite alternate;
            animation: scale 1s linear 0s infinite alternate;
}
.spinner.spinner-2:after {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0;
    left: 0;
    animation: scale 1s linear 0s infinite alternate-reverse;
}

/* spinner-3 styles */
@-webkit-keyframes scale-2 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 0;
    }
}
@keyframes scale-2 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 0;
    }
}
.spinner.spinner-3:before {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0px;
    left: 0px;
    -webkit-animation: scale-2 1s linear 0s infinite;
            animation: scale-2 1s linear 0s infinite;
}
.spinner.spinner-3:after {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-animation: scale-2 1s linear 0.5s infinite;
            animation: scale-2 1s linear 0.5s infinite;
}

/* spinner-4 styles */
@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.spinner.spinner-4:before {
    width: 117px;
    height: 117px;
    border-color: #212121;
    top: 0px;
    left: 0px;
    overflow: hidden;
}
.spinner.spinner-4:after {
    width: 16.38px;
    height: 16.38px;
    border-width: 3px;
    border-color: #212121;
    top: 50.5px;
    left: 0;
    overflow: hidden;
    -webkit-transform-origin: 62.5px;
            transform-origin: 62.5px;
    -webkit-animation: rotate 1.2s linear 0s infinite;
            animation: rotate 1.2s linear 0s infinite;
}

/* spinner-5 styles */
@-webkit-keyframes border-animate {
    0% {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    25% {
        border-top-color: #212121;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    50% {
        border-top-color: #212121;
        border-right-color: #212121;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    75% {
        border-top-color: #212121;
        border-right-color: #212121;
        border-bottom-color: #212121;
        border-left-color: transparent;
    }
    100% {
        border-top-color: #212121;
        border-right-color: #212121;
        border-bottom-color: #212121;
        border-left-color: #212121;
    }
}
@keyframes border-animate {
    0% {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    25% {
        border-top-color: #212121;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    50% {
        border-top-color: #212121;
        border-right-color: #212121;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    75% {
        border-top-color: #212121;
        border-right-color: #212121;
        border-bottom-color: #212121;
        border-left-color: transparent;
    }
    100% {
        border-top-color: #212121;
        border-right-color: #212121;
        border-bottom-color: #212121;
        border-left-color: #212121;
    }
}
.spinner.spinner-5:before {
    width: 0;
    height: 0;
    border-color: #212121;
    top: 0px;
    left: 0px;
    border-width: 62.5px;
    border-style: solid;
    -webkit-animation: border-animate 1.6s linear 0s infinite alternate;
            animation: border-animate 1.6s linear 0s infinite alternate;
}
.spinner.spinner-5:after {
    width: 81.9px;
    height: 81.9px;
    border-color: #212121;
    top: 17.55px;
    left: 17.55px;
    background-color: #fff;
}

.pageContentTitle {
    font-family: 'neuzeits-bookbook';
    text-transform: uppercase;
    font-size: 35px;
    text-align: center;
    line-height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px)  {
    .pageContentTitle {
        font-size: 45px;
        line-height: 50px;
    }
}

.pageContent {
    display: flex;
    position: relative;
    background: url(/static/media/home_bg.2c2068a4.svg) 0 0 no-repeat;
    background-position: center center;
    background-size: cover;
    height: calc(100vh - 100px);
    opacity: 0;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}

@media screen and (min-width: 1200px)  {
    .pageContent {
        height: calc(100vh - 100px);
        background-position: -800px -20px;
    }
}

.pageContent.active {
    opacity: 1;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}

@media screen and (min-width: 1200px){
    .pageContent {
        background-position: 0 0;
    }
}

.formHolder {
    background: white;
    padding: 40px;
    width: 100%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    min-height: 300px;
}

@media screen and (min-width: 500px){
    .formHolder {
        min-width: 500px;
        width: 500px;
    }
}

.formTitle {
    font-size: 40px;
    margin-bottom: 50px;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.formLabel {
    font-size: 22px;
    display: block;
    margin-bottom: 10px;
}

.formControl {
    margin-bottom: 40px;
    height: 50px;
    border: 1px solid black;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-family: 'courier_newregular';
}

.Button {
    border: none;
    height: 60px;
    background: black;
    color: white;
    font-size: 22px;
    font-weight: 600;
    font-family: 'courier_newregular';
}

.ButtonDisabled {
    border: none;
    height: 60px;
    background: rgb(145, 145, 145);
    color: white;
    font-size: 22px;
    font-weight: 600;
    font-family: 'courier_newregular';
}

.has-error {
    margin-bottom: 20px;
    color: red;
    display: block;
    margin-top: -30px;
}

.has-error-big {
    font-size: 20px;
}

.registerContent {
    opacity: 0;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}

.registerContent.active {
    opacity: 1;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}

.formSuccess {
    font-size: 26px;
}

.iconsHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.iconLink {
    display: block;
}

.iconLink ~ .iconLink {
    margin-left: 10px;
}

.recaptchaMessage {
    margin: 0;
    padding: 15px 0 0;
    color: black;
    opacity: 0.65;
    text-align: center;
    font-size: 13px;
}

.servicesContent {
    display: flex;
    height: 100%;
    position: relative;
    background: url(/static/media/services_bg.ddc46fdd.svg) -3px 0 no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    opacity: 0;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}

.servicesContent.active {
    opacity: 1;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}

.servicesList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 0 0 100vw;
    max-width: 100vw;
    height: 100%;
    padding: 20px;
}

@media screen and (min-width: 1024px){
    .servicesList {
        flex: 0 0 75vw;
        max-width: 75vw;
        padding: 10px 160px 70px 30px;
    }
}

@media screen and (min-width: 1200px){
    .servicesList {
        flex: 0 0 67vw;
        max-width: 65vw;
        padding: 10px 160px 70px 30px;
    }
}

@media screen and (min-width: 1400px){
    .servicesList {
        flex: 0 0 60%;
        max-width: 60%;
        padding: 10px 180px 70px 30px;
    }
}

@media screen and (min-height: 768px) {
    .servicesList {
        min-height: 100vh;
    }
}

.servicesItem {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    font-family: 'courier_newregular';
    display: flex;
    margin-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid black;
}

@media screen and (min-width: 768px){
    .servicesItem {
        flex: 0 0 48%;
        max-width: 48%;
    }
}

@media screen and (min-width: 1024px){
    .servicesItem {
        margin-bottom: 10px;
        padding-top: 10px;
    }
}

@media screen and (min-width: 1400px){
    .servicesItem {
        margin-bottom: 40px;
    }
}

.servicesTitle {
    text-transform: uppercase;
    word-wrap: break-word;
    width: 10px;
    line-height: 21px;
    flex: 0 0 10px;
    max-width: 10px;
    margin-right: 30px;
    font-size: 18px;
    word-spacing: 12px;
}
@media screen and (min-width: 1000px){
    .servicesTitle {
        font-size: 13px;
        line-height: 17px;
    }
}


@media screen and (min-width: 1025px){
    .servicesTitle {
        font-size: 16px;
    }
}

@media screen and (min-width: 1400px){
    .servicesTitle {
        font-size: 18px;
    }
}

.servicesTitle span ~ span {
    margin-top: 15px;
    display: block;
}

.servicesText {
    margin-top: 5px;
    font-size: 16px;
}

@media screen and (min-width: 1000px){
    .servicesText {
        font-size: 13px;
        line-height: 18px;
    }
}

@media screen and (min-width: 1024px){
    .servicesText {
        font-size: 17px;
        line-height: 18px;
    }
}

@media screen and (min-width: 1400px){
    .servicesText {
        font-size: 18px;
        line-height: 25px;
    }
}

.noMatchContent {
    height: 100vh;
}

.titleHolder {
    height: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.noMatchTitle {
    color: white;
    font-size: 110px;
    font-weight: bold;
    font-family: 'neuzeits-bookbook';
}
.termsAndPrivacyContent {
    display: flex;
    height: 100%;
    position: relative;
    background: white;
    background-size: cover;
    flex-wrap: wrap;
    opacity: 0;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}


.termsAndPrivacyContent.active {
    opacity: 1;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}



@media screen and (min-width: 991px) {
    .termsAndPrivacyContent {
    }
}

@media screen and (min-width: 1200px) {
    .termsAndPrivacyContent {
        background-position: 0 0;
    }
}

.privacyTextHolder {
    padding: 40px 70px 80px 26px;
    flex: 0 0 100%;
    max-width: 100%;
}


@media screen and (min-width: 991px) {
    .privacyTextHolder {
        flex: 0 0 70%;
        max-width: 70%;
    }
}

@media screen and (min-width: 1400px){
    .privacyTextHolder {
        /* padding: 80px 70px 80px 26px; */
    }
}

.termsAndPrivacyContent h2 {
    font-size: 120%;
    line-height: 200%;
}

.privacyText {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
}

.privacyText strong {
    font-weight: 600;
}

.privacyText ul {
    margin-top: 5px;
    margin-left: 30px;
    list-style: disc;
}
.privacyText ul.abc {
    list-style-type: lower-alpha;
}
.privacyText li {
    margin-bottom: 5px;
}

.toc-container {
    max-width: 800px;
    margin: 2rem 0;
    padding: 1rem 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.toc-list {
    list-style: decimal;
    padding-left: 1.5rem;
}

.toc-list a {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
}

.toc-list a:hover {
    color: #0056b3;
}

.toc-list li {
    padding: 5px 0;
}

.toc-list ul {
    list-style: disc;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
}

.privacyText.table {
    border: 1px solid gray;
    text-transform: none;
    overflow: auto;
}

.privacyText.table table {
    min-width: 650px;
}

.privacyText.table th,
.privacyText.table td {
    border: 1px solid gray;
    padding: 5px;
}

address {
    font-weight: 600;
}
.termsAndPrivacyContent {
    display: flex;
    height: 100%;
    position: relative;
    background: white;
    background-size: cover;
    flex-wrap: wrap;
    opacity: 0;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}


.termsAndPrivacyContent.active {
    opacity: 1;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}



@media screen and (min-width: 991px) {
    .termsAndPrivacyContent {
    }
}

@media screen and (min-width: 1200px) {
    .termsAndPrivacyContent {
        background-position: 0 0;
    }
}

.privacyTextHolder {
    padding: 40px 70px 80px 26px;
    flex: 0 0 100%;
    max-width: 100%;
}


@media screen and (min-width: 991px) {
    .privacyTextHolder {
        flex: 0 0 70%;
        max-width: 70%;
    }
}

@media screen and (min-width: 1400px){
    .privacyTextHolder {
        /* padding: 80px 70px 80px 26px; */
    }
}

.termsAndPrivacyContent h2 {
    font-size: 120%;
    line-height: 200%;
}

.privacyText {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
}

.privacyText strong {
    font-weight: 600;
}

.privacyText ul {
    margin-top: 5px;
    margin-left: 30px;
    list-style: disc;
}
.privacyText ul.abc {
    list-style-type: upper-alpha;
}
.privacyText li {
    margin-bottom: 5px;
}

.privacyText.bold {
    font-weight: 600;
}

.privacyText.italic {
    font-style: italic;
}

.privacyText.underlined {
    text-decoration: underline;
}

.toc-container {
    max-width: 800px;
    margin: 2rem 0;
    padding: 1rem 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.toc-list {
    list-style: decimal;
    padding-left: 1.5rem;
}

.toc-list a {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
}

.toc-list a:hover {
    color: #0056b3;
}

.toc-list li {
    padding: 5px 0;
}

.toc-list ul {
    list-style: none;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
}

.toc-list ul > li a {
    font-weight: normal;
}

address {
    font-weight: 600;
}
.cookiesContent {
    display: flex;
    height: 100%;
    position: relative;
    background: white;
    background-size: cover;
    flex-wrap: wrap;
    opacity: 0;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}


.cookiesContent.active {
    opacity: 1;
    -webkit-transition: opacity 5s ease-in-out;
    transition: opacity 5s ease-in-out;
}



@media screen and (min-width: 991px) {
    .cookiesContent {
    }
}

@media screen and (min-width: 1200px) {
    .cookiesContent {
        background-position: 0 0;
    }
}

.privacyTextHolder {
    padding: 40px 70px 80px 26px;
    flex: 0 0 100%;
    max-width: 100%;
}


@media screen and (min-width: 991px) {
    .privacyTextHolder {
        flex: 0 0 80%;
        max-width: 80%;
    }
}

@media screen and (min-width: 1400px){
    .privacyTextHolder {
        /* padding: 80px 70px 80px 26px; */
    }
}

.cookiesContent h2 {
    font-size: 120%;
    line-height: 200%;
}

.privacyText {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
}

.privacyText.table {
    border: 1px solid gray;
    text-transform: none;
    overflow: auto;
}

.privacyText.table table {
    min-width: 650px;
}

.privacyText.table th,
.privacyText.table td {
    border: 1px solid gray;
    padding: 5px;
}

.privacyText.title {
    font-size: 16px;
}

.privacyText ul {
    margin-top: 5px;
    margin-left: 30px;
    list-style: disc;
}
.privacyText ul.abc {
    list-style-type: lower-alpha;
}
.privacyText li {
    margin-bottom: 5px;
}
    
address {
    font-weight: 600;
}
