.aboutContent {
    display: flex;
    height: 100%;
    position: relative;
    background: white;
    background-size: cover;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 5s ease-in-out;
}

@media screen and (max-height: 1080px){
    .aboutContent {
        min-height: 100vh;
    }
}

@-moz-document url-prefix() {
    @media screen and (max-height: 1080px){
        .aboutContent {
            min-height: 100vh;
        }
    }
}

.aboutContent.active {
    opacity: 1;
    transition: opacity 5s ease-in-out;
}

@media screen and (min-width: 991px) {
    .aboutContent {
        background-image: url("./about_bg.svg");
        background-position: -141px 0;
        background-color: transparent;
    }
}

@media screen and (min-width: 1200px) {
    .aboutContent {
        background-position: 0 0;
    }
}

.aboutTextHolder {
    padding: 40px 70px 80px 26px;
    flex: 0 0 100%;
    max-width: 100%;
    height: calc(100vh - 100px);
}

@media screen and (min-width: 991px) {
    .aboutTextHolder {
        height: calc(100vh - 137px);
    }
}

@media screen and (min-width: 991px) {
    .aboutTextHolder {
        flex: 0 0 65%;
        max-width: 65%;
    }
}

@media screen and (min-width: 1400px){
    .aboutTextHolder {
        padding: 80px 70px 80px 26px;
    }
}

.aboutText {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
}

@media screen and (min-width: 500px) and (max-width: 1023px){
    .aboutText {
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px){
    .aboutText {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 1024px){
    .aboutText {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 1281px){
    .aboutText {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 36px;
    }
}

@media screen and (min-height: 768px){
    .aboutText {
        font-size: 28px;
        line-height: 32px;
    }
}

.partnerslist {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 650px;
    margin: 80px auto 0;
}

.partnersItem {
    /* width: 25%; */
    max-width: 200px;
    margin-right: 20px;
    margin-bottom: 30px;

}
.partnersItem img {
    display: block;
    max-width: 100%;
    max-height: 60px;
}

.partnersLogo {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
}