.servicesContent {
    display: flex;
    height: 100%;
    position: relative;
    background: url("./services_bg.svg") -3px 0 no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 5s ease-in-out;
}

.servicesContent.active {
    opacity: 1;
    transition: opacity 5s ease-in-out;
}

.servicesList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 0 0 100vw;
    max-width: 100vw;
    height: 100%;
    padding: 20px;
}

@media screen and (min-width: 1024px){
    .servicesList {
        flex: 0 0 75vw;
        max-width: 75vw;
        padding: 10px 160px 70px 30px;
    }
}

@media screen and (min-width: 1200px){
    .servicesList {
        flex: 0 0 67vw;
        max-width: 65vw;
        padding: 10px 160px 70px 30px;
    }
}

@media screen and (min-width: 1400px){
    .servicesList {
        flex: 0 0 60%;
        max-width: 60%;
        padding: 10px 180px 70px 30px;
    }
}

@media screen and (min-height: 768px) {
    .servicesList {
        min-height: 100vh;
    }
}

.servicesItem {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    font-family: 'courier_newregular';
    display: flex;
    margin-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid black;
}

@media screen and (min-width: 768px){
    .servicesItem {
        flex: 0 0 48%;
        max-width: 48%;
    }
}

@media screen and (min-width: 1024px){
    .servicesItem {
        margin-bottom: 10px;
        padding-top: 10px;
    }
}

@media screen and (min-width: 1400px){
    .servicesItem {
        margin-bottom: 40px;
    }
}

.servicesTitle {
    text-transform: uppercase;
    word-wrap: break-word;
    width: 10px;
    line-height: 21px;
    flex: 0 0 10px;
    max-width: 10px;
    margin-right: 30px;
    font-size: 18px;
    word-spacing: 12px;
}
@media screen and (min-width: 1000px){
    .servicesTitle {
        font-size: 13px;
        line-height: 17px;
    }
}


@media screen and (min-width: 1025px){
    .servicesTitle {
        font-size: 16px;
    }
}

@media screen and (min-width: 1400px){
    .servicesTitle {
        font-size: 18px;
    }
}

.servicesTitle span ~ span {
    margin-top: 15px;
    display: block;
}

.servicesText {
    margin-top: 5px;
    font-size: 16px;
}

@media screen and (min-width: 1000px){
    .servicesText {
        font-size: 13px;
        line-height: 18px;
    }
}

@media screen and (min-width: 1024px){
    .servicesText {
        font-size: 17px;
        line-height: 18px;
    }
}

@media screen and (min-width: 1400px){
    .servicesText {
        font-size: 18px;
        line-height: 25px;
    }
}
