.formHolder {
    background: white;
    padding: 40px;
    width: 100%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    min-height: 300px;
}

@media screen and (min-width: 500px){
    .formHolder {
        min-width: 500px;
        width: 500px;
    }
}

.formTitle {
    font-size: 40px;
    margin-bottom: 50px;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.formLabel {
    font-size: 22px;
    display: block;
    margin-bottom: 10px;
}

.formControl {
    margin-bottom: 40px;
    height: 50px;
    border: 1px solid black;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-family: 'courier_newregular';
}

.Button {
    border: none;
    height: 60px;
    background: black;
    color: white;
    font-size: 22px;
    font-weight: 600;
    font-family: 'courier_newregular';
}

.ButtonDisabled {
    border: none;
    height: 60px;
    background: rgb(145, 145, 145);
    color: white;
    font-size: 22px;
    font-weight: 600;
    font-family: 'courier_newregular';
}

.has-error {
    margin-bottom: 20px;
    color: red;
    display: block;
    margin-top: -30px;
}

.has-error-big {
    font-size: 20px;
}

.registerContent {
    opacity: 0;
    transition: opacity 5s ease-in-out;
}

.registerContent.active {
    opacity: 1;
    transition: opacity 5s ease-in-out;
}

.formSuccess {
    font-size: 26px;
}

.iconsHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.iconLink {
    display: block;
}

.iconLink ~ .iconLink {
    margin-left: 10px;
}

.recaptchaMessage {
    margin: 0;
    padding: 15px 0 0;
    color: black;
    opacity: 0.65;
    text-align: center;
    font-size: 13px;
}
