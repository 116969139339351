.termsAndPrivacyContent {
    display: flex;
    height: 100%;
    position: relative;
    background: white;
    background-size: cover;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 5s ease-in-out;
}


.termsAndPrivacyContent.active {
    opacity: 1;
    transition: opacity 5s ease-in-out;
}



@media screen and (min-width: 991px) {
    .termsAndPrivacyContent {
    }
}

@media screen and (min-width: 1200px) {
    .termsAndPrivacyContent {
        background-position: 0 0;
    }
}

.privacyTextHolder {
    padding: 40px 70px 80px 26px;
    flex: 0 0 100%;
    max-width: 100%;
}


@media screen and (min-width: 991px) {
    .privacyTextHolder {
        flex: 0 0 70%;
        max-width: 70%;
    }
}

@media screen and (min-width: 1400px){
    .privacyTextHolder {
        /* padding: 80px 70px 80px 26px; */
    }
}

.termsAndPrivacyContent h2 {
    font-size: 120%;
    line-height: 200%;
}

.privacyText {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
}

.privacyText strong {
    font-weight: 600;
}

.privacyText ul {
    margin-top: 5px;
    margin-left: 30px;
    list-style: disc;
}
.privacyText ul.abc {
    list-style-type: lower-alpha;
}
.privacyText li {
    margin-bottom: 5px;
}

.toc-container {
    max-width: 800px;
    margin: 2rem 0;
    padding: 1rem 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.toc-list {
    list-style: decimal;
    padding-left: 1.5rem;
}

.toc-list a {
    font-family: 'courier_newregular';
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    transition: color 0.3s ease;
}

.toc-list a:hover {
    color: #0056b3;
}

.toc-list li {
    padding: 5px 0;
}

.toc-list ul {
    list-style: disc;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
}

.privacyText.table {
    border: 1px solid gray;
    text-transform: none;
    overflow: auto;
}

.privacyText.table table {
    min-width: 650px;
}

.privacyText.table th,
.privacyText.table td {
    border: 1px solid gray;
    padding: 5px;
}

address {
    font-weight: 600;
}