.bgVideoHolder {
    height: 100vh;
    background: url('./bg-mobile.jpg') 0 0 repeat-y;
    position: absolute;
    z-index: -2;
    width: 100%;
}

@media screen and (min-width: 768px){
    .bgVideoHolder {
        background: url('./bg.png') 0 0 repeat-y;
    }
}

.bgVideo {
    width: 100vw;
    position: fixed;
    z-index: -1;
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
    .bgVideo {
        height: 100vh;
    }
}

@media screen and (min-width: 1400px)  {
    .bgVideo {
        height: auto;
    }
}

.appContent {
    min-height: 100vh;
}


@media screen and (min-width: 1366px) and (min-height: 1024px) {
    .appContent {
        overflow: hidden;
    }
}

.video {

}
